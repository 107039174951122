import {
  Button,
  Flex,
  Heading,
  useColorModeValue,
  useSimpleToast,
} from '@lawo/lawo-ui';
import { createOrUpdateUser } from 'api';
import { useAuth } from 'contexts';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBlob, ref } from 'firebase/storage';
import { storage } from 'firebase.config';
import useCustomerAccount from 'hooks/useCustomerAccount';
import Title from 'components/common/Title';
import Loading from 'components/common/Loading';


const PortalUserAgreement = () => {
  const [puaRead, setPuaRead] = useState(false);
  const [puaAccepting, setPuaAccepting] = useState(false);
  const [termsHtml, setTermsHtml] = useState("");
  const textBoxRef = useRef<HTMLDivElement>();
  const bgColor = useColorModeValue("#f9f9f9", "#242424");
  const navigate = useNavigate();
  const {
    currentUser,
    currentCustomerAccountId,
    role,
    puaAccepted,
    logout,
  } = useAuth();
  const currentCustomerAccount = useCustomerAccount();
  const { toast } = useSimpleToast();

  const handlePUA = async (accept: boolean) => {
    try {
      setPuaAccepting(true);
      await createOrUpdateUser(
        currentCustomerAccountId,
        currentUser.displayName,
        currentUser.email,
        role,
        accept,
      );
    } catch (e) {
      const title = accept ? 'Accept PUA failed' : 'Decline PUA failed';
      toast({
        id: 'accept-pua-error',
        status: 'error',
        title: title,
        description: 'Something went wrong, please try again',
      });
    } finally {
      setPuaAccepting(false);
    }
  };

  const fetchTerms = async () => {
    try {
      const region = currentCustomerAccount?.region;
      const regionSuffix = region ? `-${region}` : '-Rest';
      const path = `termsOfUse/terms-of-use${regionSuffix}.html`;
      const licensesRef = ref(storage, path);
      const response = await getBlob(licensesRef);
      const html = await response.text();
      setTermsHtml(html);
    } catch (error) {
      console.error(error);
    }
  };

  const onScroll = (): void => {
      if (textBoxRef.current) {
          const value = textBoxRef.current.scrollHeight - textBoxRef.current.scrollTop - textBoxRef.current.clientHeight;
          console.log(value);

          if (value <= 1) {
              setPuaRead(true);
          }
      }
  }

  useEffect(() => {
    if (puaAccepted) {
      navigate('/subscriptions');
    }
  }, [puaAccepted, navigate]);

  useEffect(() => {
    fetchTerms();
  }, [currentCustomerAccount]);

  if (puaAccepting) {
     return <Loading />;
  }

  return (
    <Flex direction="column" height="100vh" minHeight="500px" bg={bgColor} borderRadius="4px">
        <Title p="4px"/>
        <Flex direction="column" height="100%" align="center" justify="center">
            <Flex direction="column" maxWidth="1024px" maxHeight="740px">
                <Heading p="24px" mb="24px">User Agreement</Heading>
                <Flex ref={textBoxRef} onScroll={onScroll} direction="column" mb="16px" width="100%" height="100%"  overflow="auto" position="relative">
                    <div dangerouslySetInnerHTML={{ __html: termsHtml }}/>
                </Flex>
                <Flex alignSelf="flex-end" m="16px">
                    <Button mr="8px" isDisabled={!puaRead} colorScheme="lawoBlue" onClick={() => handlePUA(true)} data-test-id="accept-pua-button">
                        Accept
                    </Button>
                    <Button disabled={puaAccepting} onClick={logout}>Cancel</Button>
                </Flex>
            </Flex>
        </Flex>
    </Flex>
  );
};

export default PortalUserAgreement;
