import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./contexts";
import ThemeProvider from "./ThemeProvider";
import { CookiesProvider } from "react-cookie";
import './styles.css';

interface Props {
    children: React.ReactNode;
};

const Providers = ({ children }: Props): JSX.Element => {
  return (
    <React.StrictMode>
      <ThemeProvider>
        <CookiesProvider>
          <AuthProvider>
            <Router>{ children }</Router>
          </AuthProvider>
        </CookiesProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default Providers;
