import {
    Box,
    Fade,
    Text,
    Flex,
    SideMenu,
    SideMenuItem,
    useColorModeValue,
} from "@lawo/lawo-ui";
import { useAuth } from "contexts/AuthContext";
import { Link as RLink, Outlet, useMatch } from "react-router-dom";
import AppBar from "../AppBar";

const Container = (props: any) => {
    return (
        <Flex
            as={Fade}
            in={true}
            flex="1"
            {...props}
        />
    );
};

const Sidebar = (props: any) => {
    const bgColor = useColorModeValue("#fff", "#1d1d1d");

    return (
        <Box
            display={{ base: "none", xxl: "block" }}
            minWidth="200px"
            p="8px"
            mr="4px"
            bg={bgColor}
            borderRadius="8px"
            background={bgColor}
            {...props}
        />
    );
};

const Main = (props: any) => {
    return <Flex w="100%" {...props} />;
};

const Layout = {
    Container,
    Sidebar,
    Main,
};

interface NavMenuProps {
    to: any;
    children: any;
}

export const NavigationItem = ({ to, children, ...props }: NavMenuProps) => {
    const match = useMatch({
        path: to,
        exact: false,
    });


    const activeProps = match
        ? {
            "aria-selected": true,
        }
        : {};

    return (
        <SideMenuItem as={RLink} to={to} {...activeProps} {...props}>
            {children}
        </SideMenuItem>
    );
};

const LayoutContainer = () => {
    const { isAdmin } = useAuth();
    
    return (
        <>
            <AppBar />
            <Layout.Container>
                <Layout.Sidebar>
                    <SideMenu>
                        <NavigationItem to="/subscriptions" data-test-id="global-nav-subscriptions">
                            Subscriptions
                        </NavigationItem>
                        <NavigationItem to="/systems" data-test-id="global-nav-systems">
                            Systems
                        </NavigationItem>
                        {
                            isAdmin &&
                            <NavigationItem to="/users" data-test-id="global-nav-users">
                                Users
                            </NavigationItem>
                        }
                        <NavigationItem to="/settings" data-test-id="global-nav-settings">
                            Settings
                        </NavigationItem>
                    </SideMenu>
                </Layout.Sidebar>
                <Layout.Main>
                    <Outlet />
                </Layout.Main>
            </Layout.Container>
        </>
    );
};

export default LayoutContainer;
