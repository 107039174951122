import {
  Box,
  ToolbarItem,
  PlayIcon,
  GridTable,
  StopIcon,
  Progress,
  Text,
  RefreshIcon,
  UnusedIcon,
  WarningColorIcon,
  useSimpleToast,
  useToast,
  useDisclosure,
  Flex,
  useColorModeValue,
} from "@lawo/lawo-ui";
import useSubscriptions from "hooks/useSubscriptions";
import { useState } from "react";
import Subscription from "models/Subscription";
import { useAuth } from "contexts";
import ConfirmModal from "components/common/ConfirmModal";
import { cancelSubscription, activateSubscription, RevokeError } from "api";
import { tokensToCredits } from "lib/credits";
import { calculateEndDate, formatUTCDate } from "lib";
import { Timestamp } from "firebase/firestore";
import ResolveAssignmentsModal from "./ResolveAssignmentsModal";
import Assignment from "models/Assignment";
import Header from "pages/common/Header";
import TableToolbar from "pages/common/TableToolbar";

const Subscriptions = () => {
  const [selectedIds, setSelectedIds] = useState(new Array<string>());
  const subscriptions = useSubscriptions();
  const { currentCustomerAccountId, isAdmin } = useAuth();
  const { toast } = useSimpleToast();
  const infoToast = useToast();
  const activateModal = useDisclosure();
  const cancelModal = useDisclosure();
  const revokeAssignmentsModal = useDisclosure();
  const [assignments, setAssignments] = useState<Array<Assignment>>([]);

  const first = (): Subscription => {
    return selectedIds.length > 0
      ? subscriptions.find((s) => s.id === selectedIds[0])
      : undefined;
  };

  const isCancelEnabled = () => {
    if (selectedIds.length !== 1) {
      return false;
    }
    const sub = first();
    if (sub === undefined) {
      return false;
    }
    const active = sub?.active ?? true;
    const cancelled = sub?.cancelled ?? false;
    return active && !cancelled && isAdmin;
  };

  const isActivateEnabled = () => {
    if (selectedIds.length !== 1) {
      return false;
    }
    const sub = first();
    if (sub === undefined) {
      return false;
    }
    const active = sub?.active ?? true;
    const cancelled = sub?.cancelled ?? false;
    const autoActivate = sub?.autoActivate ?? false;
    return !active && (!cancelled||autoActivate) && isAdmin;
  };

  const handleActivate = () => {
    const sub = first();
    if (!sub || !currentCustomerAccountId) {
      return;
    }
    activateSubscription(currentCustomerAccountId, sub.id)
      .then((result) => {
        infoToast({
          id: "activate-subscription-success",
          title: "Activate Subscription",
          description: "Subscription activated okay",
          status: "info",
        });
      })
      .catch((error) => {
        toast({
          id: "activate-subscription-failed-error",
          title: "Activate Subscription Failed",
          description: "Reason: " + error.message,
          status: "error",
        });
      });
  };

  const handleCancel = () => {
    const sub = first();
    if (!sub || !currentCustomerAccountId) {
      return;
    }
    cancelSubscription(currentCustomerAccountId, sub.id)
      .then((result) => {
        setAssignments([]);
        revokeAssignmentsModal.onClose();
        infoToast({
          id: "cancel-subscription-success",
          title: "Cancel Subscription",
          description:
            "Subscription cancelled okay. You may continue using credits until subscription is deactivated.",
          status: "info",
        });
      })
      .catch((error) => {
        if (error instanceof RevokeError) {
          setAssignments(error.assignments);
          revokeAssignmentsModal.onOpen();
          return;
        }
        toast({
          id: "cancel-subscription-failed-error",
          title: "Cancel Subscription Failed",
          description: "Reason: " + error.message,
          status: "error",
        });
      });
  };

  const activateMessage = () =>
    "Activating the subscription allows the credits to be allocated to a system to unlocked licensed functionalities. You will be invoiced for subscriptions that are in an active state.";
  const activateTitle = () =>
    selectedIds.length > 1 ? "Activate Subscriptions" : "Activate Subscription";
  const cancelMessage = () =>
    selectedIds.length > 1
      ? "Are you sure you want to cancel these subscriptions? You will be able to use the credits until your next renewal date."
      : "Are you sure you want to cancel this subscription? You will be able to use the credits until your next renewal date.";
  const cancelTitle = () =>
    selectedIds.length > 1 ? "Cancel Subscriptions" : "Cancel Subscription";

  return (
    <Flex flexDirection="column" flex="1">
      <Header>
        Subscriptions
      </Header>
      <TableToolbar>
        <ToolbarItem
          icon={PlayIcon}
          onClick={activateModal.onOpen}
          isDisabled={!isActivateEnabled()}
          borderRadius="4px"
          data-test-id="subscriptions-activate-button"
        >
          Activate
        </ToolbarItem>
        <ToolbarItem
          icon={StopIcon}
          onClick={cancelModal.onOpen}
          isDisabled={!isCancelEnabled()}
          borderRadius="4px"
          data-test-id="subscriptions-cancel-button"
        >
          Cancel
        </ToolbarItem>
      </TableToolbar>
      <Box className="table-parent">
        <GridTable
          columns={[
            {
              accessorKey: "orderId",
              header: "Order ID",
              id: "orderId",
              resizable: true,
              testId: "orderId",
              sortable: true,
              size: 140,
            },
            {
              accessorKey: "purchaseOrder",
              header: "Purchase Order",
              id: "purchaseOrder",
              resizable: true,
              testId: "purchaseOrder",
              sortable: true,
              size: 200,
            },
            {
              accessorKey: "tokens",
              header: "Credits",
              id: "tokens",
              resizable: true,
              testId: "tokens",
              sortable: true,
              size: 100,
              cell: (props) => (
                <CreditsCell
                  rowData={props.cell.row.original as Subscription}
                />
              ),
            },
            {
              accessorKey: "used",
              header: "Used",
              id: "used",
              resizable: true,
              testId: "used",
              sortable: true,
              size: 100,
              cell: (props) => (
                <UsedCell
                  rowData={props.cell.row.original as Subscription}
                />
              ),
            },
            {
              accessorKey: "balance",
              header: "Remain",
              id: "balance",
              resizable: true,
              testId: "balance",
              sortable: true,
              size: 100,
              cell: (props) => (
                <BalanceCell
                  rowData={props.cell.row.original as Subscription}
                />
              ),
            },
            {
              accessorKey: "billingInterval",
              header: "Duration",
              id: "billingInterval",
              resizable: true,
              testId: "billingInterval",
              sortable: true,
              size: 120,
              cell: (props) => (
                <IntervalCell
                  cellData={props.cell.row.original.billingInterval as number}
                />
              ),
            },
            {
              accessorKey: "activatedOn",
              header: "Activated",
              id: "activatedOn",
              resizable: true,
              testId: "activatedOn",
              sortable: true,
              size: 160,
              cell: (props) => (
                <TimestampCell
                  cellData={props.cell.row.original.activatedOn as Timestamp}
                />
              ),
            },
            {
              accessorKey: "status",
              header: "Status",
              id: "status",
              resizable: true,
              testId: "status",
              sortable: true,
              size: 380,
              cell: (props) => (
                <ActiveStatusCell
                  rowData={props.cell.row.original as Subscription}
                />
              ),
            },
          ]}
          data={subscriptions}
          defaultSort={[{ id: "activatedOn", desc: true }]}
          emptyMessage="No subscriptions have been added to the system"
          selectable={{
            selectedIds,
            setSelectedIds,
          }}
          stickyLeft={2}
          style={{
            width: "100%",
            height: "calc(100vh - 212px)",
            overflow: "auto",
            borderRadius: "0px 0px 8px 8px",
          }}
          data-test-id="subscriptions-list"
        />
      </Box>
      <ConfirmModal
        {...activateModal}
        title={activateTitle()}
        message={activateMessage()}
        onOk={handleActivate}
        buttonText="Activate"
        buttonColor="lawoBlue"
      />
      <ConfirmModal
        {...cancelModal}
        title={cancelTitle()}
        message={cancelMessage()}
        requiresConfirm
        onOk={handleCancel}
        buttonText="Cancel Subscription"
        cancelText="Keep Subscription"
      />
      <ResolveAssignmentsModal
        {...revokeAssignmentsModal}
        assignments={assignments}
        customerAccountId={currentCustomerAccountId}
        subscriptionId={first()?.id}
      />
    </Flex>
  );
};

const subscriptionTypeText = (billingInterval: number) => {
  switch (billingInterval) {
    case 1:
      return "1 Month";
    default:
      return `${billingInterval} Months`;
  }
};

const IntervalCell = ({ cellData }: { cellData: number }) => {
  return <>{subscriptionTypeText(cellData)}</>;
};

const TimestampCell = ({ cellData }: { cellData: Timestamp }) => {
  const dt = cellData?.toDate();
  if (dt.getFullYear() < 2000) return null;
  return <Text>{formatUTCDate(dt)}</Text>;
};

const ActiveStatusCell = ({ rowData }: { rowData: Subscription }) => {
  const subscription = rowData;

  if (!subscription.active && !subscription.cancelled) {
    return renderInactiveStatus(subscription);
  }

  if (subscription.cancelled && !subscription.active && !subscription.autoActivate) {
    return renderDeactivatedStatus(subscription);
  }

  if (subscription.cancelled && !subscription.active && subscription.autoActivate) {
    return renderInactiveStatus(subscription);
  }

  if (subscription.active && !subscription.cancelled) {
    return renderActiveStatus(subscription);
  }

  if (subscription.active && subscription.cancelled) {
    return renderCancelledStatus(subscription);
  }

  return null;
};

// Not yet activated
function renderInactiveStatus(subscription: Subscription) {
  const text = () => {
    return subscription.autoActivate
      ? `New - Auto activates on ${formatUTCDate(
          subscription.activateOn.toDate()
        )}`
      : "New - Requires manual activation";
  };
  return (
    <Flex direction="row" justifyContent="center">
      <UnusedIcon />
      <Text opacity=".4" lineHeight="24px" ml={2}>
        {text()}
      </Text>
    </Flex>
  );
}

// Used to be active, but cancelled and deactivated (dead essentially)
function renderDeactivatedStatus(subscription: Subscription) {
  const endDate = subscription.deactivateOn?.toDate();
  return (
    <Flex direction="row" justifyContent="center">
      <UnusedIcon />
      <Text opacity=".4" lineHeight="24px" ml={2}>
        Deactivated - Expired 23.59 on {formatUTCDate(endDate)}
      </Text>
    </Flex>
  );
}

// Normal.
function renderActiveStatus(subscription: Subscription) {
  let endDate = calculateEndDate(
    subscription.activatedOn,
    subscription.billingInterval
  );
  // add 1 day to the end date to make it inclusive
  endDate.setDate(endDate.getDate() + 1);
  return (
    <Flex flexDirection="row" justifyContent="center">
      <RefreshIcon />
      <Text lineHeight="24px" ml={2}>
        Active - Renews on {formatUTCDate(endDate)}
      </Text>
    </Flex>
  );
}

function renderCancelledStatus(subscription: Subscription) {
  const endDate = subscription.deactivateOn?.toDate();
  return (
    <Flex flexDirection="row" justifyContent="center">
      <WarningColorIcon />
      <Text lineHeight="24px" ml={2}>
        Cancelled - Expires 23.59 on {formatUTCDate(endDate)}
      </Text>
    </Flex>
  );
}

const CreditsCell = ({ rowData }: { rowData: Subscription }) => {
  return (
    <>
      <Text>{tokensToCredits(rowData.tokens)}</Text>
    </>
  );
};

const BalanceCell = ({ rowData }: { rowData: Subscription }) => {
  return (
    <>
      <Flex flex={1} flexDirection="column">
        <Text>{tokensToCredits(rowData.balance)}</Text>
      </Flex>
    </>
  );
};

const UsedCell = ({ rowData }: { rowData: Subscription }) => {
  return (
    <>
      <Flex flex={1} flexDirection="column">
        <Text>{tokensToCredits(rowData.tokens - rowData.balance)}</Text>
      </Flex>
    </>
  );
}

export default Subscriptions;
